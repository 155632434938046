import Logo1 from "../../assets/images/award1_202107.png";
import Logo2 from "../../assets/images/award2_202107.png";
import MF21UC from "../../assets/images/MF21UC.png";
import Mortgage from "../../assets/images/mortage2021.jpg";
import FASA from "../../assets/images/award-fasa_border.jpeg";
import MI from "../../assets/images/award-mi_border.png";
import Finance from "../../assets/images/finance_planning_group_logo.svg";
import Lender from "../../assets/images/winner_best_intermediary_lender_border.png";
import MAB from "../../assets/images/MAB_full_logo_border.png";
import MoneyAge from "../../assets/images/MoneyAge-Mortgage-Awards-Image.jpg";
import BestMarketing from "../../assets/images/MIMA-Logo .jpg";
import MortgageStrategy from "../../assets/images/MSA-Awards.png";
import LegalGeneral from "../../assets/images/L&G_overall.jpg";
import MF22 from "../../assets/images/MF22_Win.jpg";
import HSBC_FRA22_Winners from "../../assets/images/HSBC_FRA22_Winners.jpg";
import BMALU from "../../assets/images/14_LENDER_BUS_DEV_L_UNDERDOWN.png";
import BMAAM from "../../assets/images/13_LENDER_TELEPHONY_MANAGER-A_MAYALA.png";
import MOTF from "../../assets/images/MOTF.jpg";
import YMA from "../../assets/images/YMA22-23_WINNER_best.remort_hsbc.jpg";
import MAW2023 from "../../assets/images/MortgageAwardsWinners2023-Mortgage_Lender_Customer_Service_of_the_Year.jpg";
import MAWB2023 from "../../assets/images/MortgageAwardsWinners2023-Bank_Mortgage_Provider_of_the_Year.jpg";
import LGW2023 from "../../assets/images/16.L&G.2023.WINNERS_logos_over£1bn_HSBC.png";
import JESS from "../../assets/images/15.WINNER'S.LOGOS_jess.whitwell.png";

const AWARDS = [
  {
    title: ["British Mortgage Awards (BMAs)"],
    date: "July 2024",
    subTitles: ["Lender - Telephony Relationship Manager", "Megan Davies", "<br />", "Change Maker", "Tracie Burton"],
    id: 75,
  },
  {
    title: ["Moneyfacts Awards"],
    date: "July 2024",
    subTitles: ["Best Fixed Rate Mortgage Provider - HSBC UK"],
    id: 74,
  },
  {
    title: ["Moneyfacts Awards"],
    date: "July 2024",
    subTitles: ["Best online Mortgage Provider - HSBC UK"],
    id: 73,
  },
  {
    title: ["Just Mortgages"],
    date: "April 2024",
    subTitles: ["Best BDM Team - HSBC UK"],
    id: 72,
  },
  {
    title: ["Mortgage Introducer"],
    date: "March 2024",
    subTitles: ["Best Women Mortgage Leaders in the UK - Elite Women - HSBC UK – Tracie Burton"],
    id: 71,
  },
  {
    title: ["MoneyAge – The Mortgage Awards"],
    date: "February 2024",
    subTitles: [
      "Overall Mortgage Lender of the Year - HSBC UK",
      "Bank Mortgage Provider of the Year - HSBC UK",
      "Mortgage Lender Customer Service of the Year - HSBC UK",
    ],
    id: 70,
  },
  {
    title: ["Mortgage Finance Gazette Awards"],
    date: "January 2024",
    subTitles: ["Top Rated - Mainstream Lender", "Rated - BTL Lender"],
    id: 69,
  },
  {
    title: ["TMG Awards"],
    date: "January 2024",
    subTitles: ["Best Lender - HSBC UK"],
    id: 68,
  },
  {
    title: ["Financial Reporter Awards"],
    date: "2023",
    subTitles: ["BDM of the Year - Carol Murray"],
    id: 67,
  },
  {
    title: ["Financial Reporter Awards"],
    date: "2023",
    subTitles: ["Best Lender for Technology"],
    id: 66,
  },
  {
    title: ["Brook Finance"],
    date: "2023",
    subTitles: ["Best Lender for Products"],
    id: 65,
  },
  {
    title: ["Mortgage Finance Gazette Awards"],
    date: "2023",
    subTitles: ["Mainstream Lender & BTL Lender"],
    id: 64,
  },
  {
    title: ["Mortgage Introducer Elite Women Award"],
    date: "2023",
    subTitles: ["Elite Women - Lisa Meredith"],
    id: 63,
  },
  {
    title: ["Mortgage Force Awards"],
    date: "2023",
    subTitles: ["BDM of the Year - Ruth Prestidge"],
    id: 62,
  },
  {
    title: ["Moneyfacts Awards"],
    date: "2023",
    subTitles: ["Mortgage Intermediaries Choice"],
    id: 61,
  },
  {
    title: ["Financial Adviser Service Awards"],
    date: "2023",
    subTitles: ["Mortgage Providers 4* lender"],
    id: 60,
  },
  {
    title: ["Financial Reporter – Women’s Recognition Awards"],
    date: "2023",
    subTitles: ["Woman in the Mortgage Industry - Tracie Burton"],
    id: 59,
  },
  {
    title: ["Mortgage Introducer Awards"],
    date: "2023",
    subTitles: ["5* Lender"],
    id: 58,
  },
  {
    title: ["Your Mortgage Awards"],
    date: "2023",
    subTitles: ["Best Remortgage Lender"],
    id: 57,
  },
  {
    title: ["HLP Awards"],
    date: "2023",
    subTitles: ["Best BDM Team"],
    id: 56,
  },
  {
    title: ["Mortgage Introducer"],
    date: "2023",
    subTitles: ["Mortgage Lender of the Year"],
    id: 55,
  },
  {
    title: ["Financial Reporter – MIMA"],
    date: "2023",
    subTitles: ["Best Marketing – Mortgages"],
    id: 54,
  },
  {
    title: ["Alexander Hall Annual Awards"],
    date: "2023",
    subTitles: ["Best BDM - Laura Underdown"],
    id: 53,
  },
  {
    title: ["Mortgage Force Awards"],
    date: "2023",
    subTitles: ["Lender of the Year"],
    id: 52,
  },
  {
    title: ["Coreco Awards"],
    date: "2023",
    subTitles: ["Best BDM - Laura Underdown"],
    id: 50,
  },
  {
    title: ["British Mortgage Awards"],
    date: "June 2023",
    subTitles: ["Lender: Business Development - Jess Whitwell"],
    imgSrc: JESS,
    id: 49,
  },
  {
    title: ["L&G Mortgage Club Awards"],
    date: "June 2023",
    subTitles: ["Best Large Lender - Over £1bm in lending"],
    imgSrc: LGW2023,
    id: 48,
  },
  {
    title: ["MoneyAge The Mortgage Awards"],
    date: "June 2023",
    subTitles: ["Bank Mortgage Provider of the Year"],
    imgSrc: MAWB2023,
    id: 47,
  },
  {
    title: ["MoneyAge The Mortgage Awards"],
    date: "June 2023",
    subTitles: ["Mortgage Lender Customer Service of the Year"],
    imgSrc: MAW2023,
    id: 46,
  },
  {
    title: ["YourMortgage Awards"],
    date: "November 2022",
    subTitles: ["Best Remortgage Lender"],
    imgSrc: YMA,
    id: 45,
  },
  {
    title: ["Women's Recognition Awards"],
    date: "September 2022",
    subTitles: ["Marketer of the Year – Lisa Meredith"],
    imgSrc: MOTF,
    id: 44,
  },
  {
    title: ["British Mortgage Awards"],
    date: "July 2022",
    subTitles: ["Achile Mayala"],
    imgSrc: BMAAM,
    id: 43,
  },
  {
    title: ["British Mortgage Awards"],
    date: "July 2022",
    subTitles: ["Lender: Business Development -", "Laura Underdown"],
    imgSrc: BMALU,
    id: 42,
  },
  {
    title: ["Financial Reporter Awards"],
    date: "June 2022",
    subTitles: ["Best Mortgage Lender", "BDM of The Year -  Lisa Taylor"],
    imgSrc: HSBC_FRA22_Winners,
    id: 41,
  },
  {
    title: ["Moneyfacts Awards"],
    date: "June 2022",
    subTitles: ["Mortgage Intermediaries Choice"],
    imgSrc: MF22,
    id: 40,
  },
  {
    title: ["Legal & General Mortgage Club Awards"],
    date: "June 2022",
    subTitles: ["Best Overall Lender", "Business Development Manager of the Year – Laura Underdown"],
    imgSrc: LegalGeneral,
    id: 39,
  },
  {
    title: ["Mortgage Strategy Awards"],
    date: "May 2022",
    subTitles: ["Best BDM Team"],
    imgSrc: MortgageStrategy,
    id: 39,
  },
  {
    title: ["Mortgage Industry Marketing Awards"],
    date: "May 2022",
    subTitles: ["Best Marketing Mortgages - Lisa Meredith"],
    imgSrc: BestMarketing,
    id: 38,
  },
  {
    title: ["MoneyAge Mortgage Awards"],
    date: "May 2022",
    subTitles: [
      "First Time Buyer Mortgage Lender of the Year",
      "Remortgage Lender of the Year",
      "Overall Mortgage Lender of the Year",
      "Lender Customer Service of the Year",
    ],
    imgSrc: MoneyAge,
    id: 37,
  },
  {
    title: ["Brook Financial (MAB)"],
    date: "April 2022",
    subTitles: ["Best Lender for Products", "BDM of the Year – Phil Vaughan"],
    imgSrc: MAB,
    id: 36,
  },
  {
    title: ["Mortgage Finance Gazette Awards"],
    date: "January 2022",
    subTitles: ["Best Intermediary Lender"],
    imgSrc: Lender,
    id: 35,
  },
  {
    title: ["Financial Planning Group Annual Awards"],
    date: "January 2022",
    subTitles: ["Best Lender for Customer Service", "Best BDM Experience – Tom Spatchurst"],
    imgSrc: Finance,
    id: 34,
  },
  {
    title: ["Mortgage Introducer Awards"],
    date: "November 2021",
    subTitles: ["Mortgage Lender of the Year"],
    imgSrc: MI,
    id: 32,
  },
  {
    title: ["Financial Adviser Awards"],
    date: "November 2021",
    subTitles: ["Mortgage Provider 5* Service"],
    imgSrc: FASA,
    id: 33,
  },
  {
    title: ["British Mortgage Awards"],
    date: "October 2021",
    subTitles: ["Operations/Credit Risk – Jon Cole", "Business Development – Siobhan Moran"],
    id: 30,
  },
  {
    title: ["Mortgage Introducer Scottish Mortgage Awards"],
    date: "October 2021",
    subTitles: ["Mainstream Lender of the Year"],
    id: 29,
  },
  {
    title: ["HLP Conference"],
    date: "September 2021",
    subTitles: ["Best BDM Team"],
    id: 28,
  },
  {
    title: ["Mortgage Strategy Awards"],
    date: "September 2021",
    subTitles: ["Best Mortgage Lender"],
    id: 31,
    imgSrc: Mortgage,
  },
  {
    title: ["Financial Reporter Women’s Recognition Awards"],
    date: "September 2021",
    subTitles: ["BDM of the Year – Teresa Pipon"],
    id: 27,
  },
  {
    title: ["Mortgage Force Annual Conference"],
    date: "July 2021",
    subTitles: ["Best Overall Lender"],
    id: 26,
  },
  {
    title: ["Money Age – The Mortgage Awards"],
    date: "July 2021",
    subTitles: [
      "Best Mortgage Provider of the Year",
      "Mortgage Lender Provider of the Year",
      "Mortgage Lender Customer Service of the Year",
      "Highly Commended – First Time Buyer Mortgage",
      "Lender of the Year",
    ],
    id: 25,
  },
  {
    title: ["What Mortgage Awards"],
    date: "July 2021",
    subTitles: ["Highly Commended", "Best Remortgage Lender"],
    id: 24,
  },
  {
    title: ["Financial Reporter"],
    date: "July 2021",
    subTitles: ["BDM of the Year – Ashley Dale"],
    id: 1,
  },
  {
    title: ["St. James Place"],
    date: "June 2021",
    subTitles: ["Best Service from a BDM Team"],
    id: 2,
  },
  {
    title: ["Moneyfacts Awards"],
    date: "June 2021",
    subTitles: ["Best Bank Mortgage Provider", "Best Fixed Rate Mortgage Provider"],
    imgSrc: MF21UC,
    id: 3,
  },
  {
    title: ["Your Money Awards"],
    date: "March 2021",
    subTitles: ["Best Mortgage Provider"],
    id: 4,
  },
  {
    title: ["Brook Financial"],
    date: "January 2021",
    subTitles: ["Best Lender for Products", "Best Overall Lender", "BDM of the Year - Phil Vaughan"],
    id: 5,
  },
  {
    title: ["MAB Awards"],
    date: "January 2021",
    subTitles: [
      "Best Lender Consistency of Service",
      "Best BDM – Phil Vaughan",
      "Best Lender Communication",
      "Best Lender Relationship",
    ],
    imgSrc: Logo1,
    id: 6,
  },
  {
    title: ["Your Mortgage Awards"],
    date: "January 2021",
    subTitles: ["Best Intermediary Mortgage Lender", "Best First Time Buyer Mortgage Lender"],
    id: 7,
  },
  {
    title: ["British Mortgage Awards"],
    date: "December 2020",
    subTitles: ["Business Leader >£5bn – Chris Pearson", "Head of Sales – Richard Beardshaw"],
    id: 8,
  },
  {
    title: ["Coreco Awards 2020"],
    date: "December 2020",
    subTitles: ["Best Overall Lender", "Best Lender for Communications during COVID&#8209;19"],
    id: 9,
  },
  {
    title: ["Mortgage Introducer Awards"],
    date: "December 2020",
    subTitles: ["Lender of the Year"],
    id: 10,
  },
  {
    title: ["Financial Adviser Service Awards"],
    date: "November 2020",
    subTitles: ["4 Star Award"],
    id: 11,
  },
  {
    title: ["Mortgage Finance Gazette Awards"],
    date: "November 2020",
    subTitles: ["Best Overall Lender", "Best National Bank", "Best Online Lender"],
    imgSrc: Logo2,
    id: 12,
  },
  {
    title: ["Property Reporter Awards"],
    date: "November 2020",
    subTitles: ["Best Mortgage Lender"],
    id: 13,
  },
  {
    title: ["L&G Mortgage Club Awards"],
    date: "November 2020",
    subTitles: ["Lender Recognition Award"],
    id: 14,
  },
  {
    title: ["Financial Reporter Women’s Recognition Award"],
    date: "August 2020",
    subTitles: ["Woman in Management – Amanda Fenner", "Female BDM of the Year – AimieJo Shutt"],
    id: 15,
  },
  {
    title: ["Knight Frank Finance"],
    date: "July 2020",
    subTitles: ["Corona BDM Award – Laura Underdown"],
    id: 16,
  },
  {
    title: ["Moneyfacts"],
    date: "June 2020",
    subTitles: ["Best Fixed Rate Mortgage Provider", "Best Bank Mortgage Provider"],
    id: 17,
  },
  {
    title: ["Financial Reporter"],
    date: "May 2020",
    subTitles: ["Best BDM – Ashley Dale", "Best Mortgage Lender"],
    id: 18,
  },
  {
    title: ["Mortgage Force"],
    date: "February 2020",
    subTitles: ["Best Newcomer"],
    id: 19,
  },
  {
    title: ["PRIMIS Annual Conference"],
    date: "January 2020",
    subTitles: ["Stand-out Personality (Best BDM) – AimieJo Shutt", "Best Lender (Innovation)"],
    id: 20,
  },
  {
    title: ["Private Finance"],
    date: "January 2020",
    subTitles: ["Best BDM – Laura Underdown"],
    id: 21,
  },
  {
    title: ["Brook Financial"],
    date: "January 2020",
    subTitles: ["Best BDM – Phil Vaughan"],
    id: 22,
  },
  {
    title: ["Financial Planning Group"],
    date: "January 2020",
    subTitles: ["Best Lender for Service,", "including BDM – Tom Spatchurst"],
    id: 23,
  },
];

export default AWARDS;
